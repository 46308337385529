@use '@angular/material' as mat;
@use './m3-theme' as custom-theme;

@include mat.elevation-classes();
@include mat.app-background();

$mat3-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: mat.$azure-palette,
    tertiary: mat.$blue-palette,
  ),
  density: (
    scale: 0,
  )
));

:root {
  @include mat.all-component-themes(custom-theme.$light-theme);
  //@include mat.all-component-themes($mat3-theme);
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Inter, "Helvetica Neue", sans-serif;
}

.app-container {
  @apply w-full p-4 mx-auto flex flex-col h-full;
}

.app-page-content {
  @apply p-4 container mx-auto;
}

.app-container-padding {
  @apply p-4;
}

.app-header {
  @apply flex justify-between items-center gap-5 p-4 bg-white;
}

.line {
  @apply h-[1px] bg-neutral-300 w-full;
}

.line-light {
  @apply h-[1px] bg-neutral-100 w-full;
}

@layer utilities {
  /* Global scrollbar styling */
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f3f4f6;
  }

  ::-webkit-scrollbar-thumb {
    cursor: pointer;
    background-color: #a0aec0;
    border-radius: 100px;
    transition: width 0.2s ease-in-out, background-color 0.2s ease-in-out;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #718096;
    width: 10px;
  }
}

.cdk-overlay-container:focus,
.cdk-overlay-pane:focus,
.cdk-overlay-container *:focus {
  outline: none !important;
}
